<div class="formbox">
    <div class="cabecera">
        MÓVIL - LÍNEAS POR CONTRATO
    </div>
    <div class="cabecera2">
        <select class="form-control contratoSelect" [(ngModel)]="selectContrato" #event name="contratos" (change)="getlineas($event.target.value)" [disabled]="enbusqueda">
            <option value="0">SELECCIONE UN CONTRATO:</option>
            <option value={{d.cof}}|{{d.con}}|{{d.promo}} *ngFor="let d of data2">{{d.des}} - {{d.cof}} {{d.alias ? '('+d.alias+')' : '' }}</option>
        </select>
    </div>
    <button *ngIf="promo" class="dt-button-large" (click)="descargaCsv()">LÍNEAS PROMOCIÓN</button>
    <select id="sctFiltro" *ngIf="this.dataFiltrada" [(ngModel)]="this.seleccionFiltro" (change)="this.cambiarFiltrado($event.target.value)">
        <option value="T">TODOS</option>
        <option value="N">SIM</option>
        <option value="S">eSIM</option>
    </select>
    <img *ngIf="load" style="height: 30px;width: 30px; padding-top: 3px;margin: auto;display: flex;" src="assets/img/loading2.gif">
    
    <div *ngIf="carga">
        <img  style="height: 30px;width: 30px; padding-top: 3px;margin: auto;display: flex;" src="assets/img/loading2.gif">
    </div>
    
    <div class="divTabla" *ngIf="this.dataFiltrada">
        <div class="container" style="width: 95%; zoom:90%">
            <div id="datatableMovil" *ngIf="this.dataFiltrada" style="display: flex;justify-content: space-evenly;zoom: 90%;">
                <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" id="dt" *ngIf="this.dataFiltrada">
                    <thead style="font-family: MontserratBold; text-transform: uppercase;">
                        <tr>
                            <th>Peticion</th>
                            <th>Activación</th>
                            <th>Tarifa</th>
                            <th>Titular</th>
                            <th>Dni</th>
                            <th>Número</th>
                            <th>Multisim</th>
                            <th>Doble de Gigas</th>
                            <th>Cambio</th>
                            <th>Hora</th>
                            <th>Otras acciones</th>
                            <th>Servicios</th>
                        </tr>
                    </thead>
                    <tbody style="font-family: MontserratRegular;text-align: center;">
                        <tr *ngFor="let c of this.dataFiltrada; let i= index">
                            <td>{{c.RegPeticion}}</td>
                            <td>{{c.FecActivacion}}</td>
                            <td>{{c.Tarifa}}</td>
                            <td>{{c.DesTitular}}</td>
                            <td>{{c.Dni}}</td>
                            <td>
                                {{c.Numero}}<br>
                                <small>({{ c.Esim == 'N' ? 'SIM' : 'eSIM' }} - {{c.Platafor}})</small>                                
                            </td>
                            <td>{{c.MultiSIM}}</td>
                            <td [ngClass]="c.DobleGB=='N'? 'gigasInactivos': 'gigasActivos'">{{(c.DobleGB=='N')?'DUPLICAR GB':'GB DUPLICADOS'}}</td>
                            <td>{{c.Cambio}}</td>
                            <td>{{c.Hora}}</td>
                            <td>
                                <button type="button" (click)="setLinea(c.RegPeticion, c.FecActivacion, c.Tarifa, c.DesTitular, c.Dni, c.Numero, c.MultiSIM, c.Cambio, c.Hora, c.DobleGB,c.NextTarifa,c.Robo)" class="detalleB">
                                </button>
                            </td>
                            <td>
                                <button type="button" (click)="servicios(c.RegPeticion, c.Numero)" class="detalleB">
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>

</div>

<div style="height: 10em; color: rgba(0, 0, 0, 0); background-color: #ebeff2;">
    <label>.</label>
</div>
