const mode = {
    // urlApiFtth: "http://localhost:8000",
    // urlApiBitstream: "http://localhost:8000",
    // urlApiMovil: "http://localhost:8000",
    // urlApiFijo: "http://localhost:8000",
    // urlApiMarcablanca: "http://localhost:8000",
    
    
    urlApiFtth: 'https://apiftth.ptvtelecom.com',
    urlApiBitstream: 'https://bitstream.ptvtelecom.com',
    urlApiMovil: 'https://apimovil.ptvtelecom.com',
    urlApiFijo: 'https://apifijo.ptvtelecom.com​',
    urlApiMarcablanca: 'https://apimarcablanca.ptvtelecom.com',
};

export const environment = {
    //_______________________ENTORNO_________________________________
    production: false,


    //_______________________________TOKEN GENERICO ACCESO___________________________________________
    GENERICTOKEN: 'c107add9c013e11f373918d8ff7510ca',    
       
    
    // endpointdatos: 'http://localhost:8081',
    // endpointdatos: 'http://212.225.223.78:8081',
    // endpointdatos: mode.urlApiFijo+'/swagger-ui',      
    
    cOperador: 'http://212.225.255.130:8010/ws/wsopedona/',
    setUser: '/contratos/setUsuario', // no usado
    getBonosMovil: '/lineas/getBonos', // no usado


    //_______________________________________FTTH_____________________________________
    getsubcriptions: mode.urlApiFtth+'/getSuscriptions',
    getSuscriptionsByContract: mode.urlApiFtth+'/getSuscriptionsByContract',
    orderStatus: mode.urlApiFtth+'/orderStatus',
    ontDiagnosis: mode.urlApiFtth+'/ontDiagnosis',
    diagnosisCodes: mode.urlApiFtth+'/diagnosisCodes',
    getRates: mode.urlApiFtth+'/getRates',
    migrateSpeed: mode.urlApiFtth+'/migrateSpeed',
    blockUser: mode.urlApiFtth+'/blockUser',
    deleteSuscriber: mode.urlApiFtth+'/deleteSuscriber',
    gettlementTypes: mode.urlApiFtth+'/gettlementTypes',
    normalizedAddress: mode.urlApiFtth+'/normalizedAddress',
    coverage: mode.urlApiFtth+'/coverage',
    getProvincias: mode.urlApiFtth+'/getProvincias',
    getPoblacion: mode.urlApiFtth+'/getPoblacion',
    getStreets: mode.urlApiFtth+'/getStreets',
    createNewSubcription: mode.urlApiFtth+'/createSubscription',
    checkDocumentValidate: mode.urlApiFtth+'/checkDocument',
    getSubscriptionStateOrange: mode.urlApiFtth+'/getSubscriptionState',


    /*________________________________________BITSTREAM_______________________________________________________ */
    loginBitstream: mode.urlApiBitstream+'/user/login',
    refreshBitstream: mode.urlApiBitstream+'/user/refresh',
    getProvinciasBitstream: mode.urlApiBitstream+'/cobertura/getProvincias',
    getMunicipiosBitstream: mode.urlApiBitstream+'/cobertura/getMunicipios/',
    getCalleBitstream: mode.urlApiBitstream+'/cobertura/getCalles/',
    getCoberturaBitstream: mode.urlApiBitstream+'/cobertura/getCobertura/',
    getPedidosBitstream: mode.urlApiBitstream+'/pedidos/recuperarplataforma',
    contratoNuevoBitstream: mode.urlApiBitstream+'/contrato/nuevo',
    subirPedidosBitstream: mode.urlApiBitstream+'/pedidos/subir',

    
    /*___________________________________APIMOVIL_______________________________________________________*/    
    login: mode.urlApiMovil+'/api/login',
    checkMobileLines: mode.urlApiMovil+'/api/lineas/getLineasCuarto',
    getTarifaMobile: mode.urlApiMovil+'/api/lineas/GetTarifasmovil',
    updateTarifa: mode.urlApiMovil+'/api/lineas/updateTarifa',
    getCliente: mode.urlApiMovil+'/api/cliente/getcliente',
    uploadDocumentation: mode.urlApiMovil+'/api/contratos/uploadDocumentation',
    getDocumentationNames: mode.urlApiMovil+'/api/contratos/getDocumentationNames',
    downloadDocumentation: mode.urlApiMovil+'/api/contratos/downloadDocumentation',
    downloadLogoOperator: mode.urlApiMovil+'/api/operador/getLogo',
    uploadLogoOperator: mode.urlApiMovil+'/api/operador/uploadLogo',
    getMostrarRiesgo: mode.urlApiMovil+'/api/lineas/getMostrarRiesgo',
    getGuardarLimiteRiesgo: mode.urlApiMovil+'/api/lineas/getGuardarLimiteRiesgo',
    getListCdr: mode.urlApiMovil+'/api/operador/getListCdr',
    getCdr: mode.urlApiMovil+'/api/operador/getCdr',
    cambioSim: mode.urlApiMovil+'/api/lineas/cambioSim',
    bonusForLine: mode.urlApiMovil+'/api/lineas/bonusForLine',
    activateBonus: mode.urlApiMovil+'/api/lineas/activateBonus',
    cambioTitular: mode.urlApiMovil+'/api/lineas/cambioTitular',
    checkFreeNumberMobile: mode.urlApiMovil+'/api/lineas/checkFreeNumberMobile',
    advancedFind:mode.urlApiMovil+'/api/lineas/advancedFind',
    launchDobleGigas: mode.urlApiMovil+'/api/lineas/lauchDoubleGb',
    getTarifaFamilar: mode.urlApiMovil+'/api/lineas/getTarifaFamiliar',
    startGroupLines: mode.urlApiMovil+'/api/lineas/startGroupLines',
    addLineGroup: mode.urlApiMovil+'/api/lineas/addLineGroup',
    exportacionesMoviles: mode.urlApiMovil+'/api/lineas/exportacionesMoviles',
    getListSharedGroup: mode.urlApiMovil+'/api/lineas/getListSharedGroup',
    bajaLineGroup: mode.urlApiMovil+'/api/lineas/getBajaCompartida',
    getForzarCambioTarifa: mode.urlApiMovil+'/api/lineas/getForzarCambioTarifa',
    controlBajas: mode.urlApiMovil+'/api/lineas/getInfoBajas',
    gestionPortabilidad: mode.urlApiMovil+'/api/lineas/gestionPortabilidades',
    getInvoices: mode.urlApiMovil+'/api/lineas/getInvoices',
    obtenerFacturasPorOperador:  mode.urlApiMovil+'/api/lineas/obtenerFacturasPorOperador',
    verpdfFactura: mode.urlApiMovil+'/api/lineas/verpdfFactura',
    getListControlRiesgo: mode.urlApiMovil+'/api/lineas/getListControlRiesgo',
    bloqueo: mode.urlApiMovil+'/api/lineas/bloquear',
    getListBloqueados: mode.urlApiMovil+'/api/lineas/getListBloqueados',
    modificaServicioMovil: mode.urlApiMovil+'/api/lineas/modificaServicioMovil',
    historicTarifa: mode.urlApiMovil+'/api/lineas/historicTarifas',
    historicBonos: mode.urlApiMovil+'/api/lineas/historicBonos',
    getBonosList: mode.urlApiMovil+'/api/lineas/getBonosList',
    resetPassword: mode.urlApiMovil+'/api/resetPass',
    exportarEnPDfContratoMovil: mode.urlApiMovil+'/api/lineas/generateContractPdf',
    bloqueoDesbloqueo: mode.urlApiMovil+"/api/lineas/bloqueoDesbloqueo",
    getTarifaOrigen: mode.urlApiMovil+"/api/lineas/getOrigen",
    downloadCsvPromoVerano:mode.urlApiMovil+"/api/lineas/downloadCsv",
    puedeDobleGigas: mode.urlApiMovil+'/api/lineas/puedeDobleGigas',
    pdfWholesale: mode.urlApiMovil+'/api/lineas/pdfWholesale',
    getContratoSubrrogacion: mode.urlApiMovil+'/api/contratos/getContratoSubrrogacion',
    setCliente: mode.urlApiMovil+'/api/cliente/setCliente',
    modifyCliente: mode.urlApiMovil+'/api/cliente/modifyClient',    
    setLineasMovil: mode.urlApiMovil+'/api/lineas/setLinea',
    getUsuario: mode.urlApiMovil+'/api/cliente/getcliente',
    getContratosMovil: mode.urlApiMovil+'/api/contratos/getContratosMovil',
    getContratos_: mode.urlApiMovil+'/api/contratos/devuelveContratos',
    getTarifaMovil: mode.urlApiMovil+'/api/lineas/GetTarifasmovil',
    getPais: mode.urlApiMovil+'/api/cliente/getPais',
    getConsumoMovil: mode.urlApiMovil+'/api/lineas/getConsumo',
    getCallsForLine: mode.urlApiMovil+'/api/lineas/getCallsForLine',
    checksim: mode.urlApiMovil+'/api/lineas/checkSim',
    InfoSIMMovil: mode.urlApiMovil+'/api/lineas/infoSim',
    verlistMutisim: mode.urlApiMovil+'/api/lineas/listarMultisim',
    getSimCodes: mode.urlApiMovil+'/api/lineas/getSimCodes',
    getServicioFijo: mode.urlApiMovil+'/api/lineas/getServiciosFijos',
    modificarServicioFijo: mode.urlApiMovil+'/api/lineas/modificaServicioFijo',
    getServicioMovil: mode.urlApiMovil+'/api/lineas/getServiciosMovil',
    modificarServicioMovil: mode.urlApiMovil+'/api/lineas/modificaServicioMovil',
    getLineasMovil: mode.urlApiMovil+'/api/lineas/getLineas',
    enviarSMSConfigAPN: mode.urlApiMovil + '/api/lineas/enviarSMSConfigAPN',


    //____________________________________________APIFIJO____________________________________________
    getContratoPdfBase64: mode.urlApiFijo+'/contratos/getContratoPdfBase64',
    setUsuarioFijo: mode.urlApiFijo+'/contratos/setUsuario',
    getContratos: mode.urlApiFijo+'/contratos/getContratos',
    getDetalleLineaFija: mode.urlApiFijo+'/lineas/getDetalleLineaFija',
    getConsumofijo: mode.urlApiFijo+'/lineas/getConsumoFijo',
    bloqueofija: mode.urlApiFijo+'/lineas/bloqueoFija',
    buscarLineaFija: mode.urlApiFijo+'/lineas/buscarLineaFija',
    cambioTitularFijo: mode.urlApiFijo+'/lineas/cambioTitular',
    getGestionRechazos: mode.urlApiFijo+'/documentacion/gestionRechazos',
    gestionPortabilidadesFijos: mode.urlApiFijo+'/documentacion/gestPortabilidad',
    getexportFijas: mode.urlApiFijo+'/lineas/getExportaciones',
    uplodadDocsFijo: mode.urlApiFijo+'/documentacion/postDocumento',
    getDocsFijo: mode.urlApiFijo+'/documentacion/getDocumentos',
    downloadDocsFijo: mode.urlApiFijo+'/documentacion/downloadDocs',
    getLineasFijo: mode.urlApiFijo+'/lineas/getLineas',
    setLineasFijo: mode.urlApiFijo+'/lineas/setLineaFija',

    //_________________________________APIMARCABLANCA________________________________________
    getBuzonIncidencias:mode.urlApiMarcablanca+'/api/incidencias/obtenerBuzonesIncidencias',
    crearIncidencia :mode.urlApiMarcablanca+'/api/incidencias/crearIncidencia',
    listarAlbaranes: mode.urlApiMarcablanca+'/api/operador/obtenerListaAlbaranes',
    descargarAlbaran: mode.urlApiMarcablanca+'/api/operador/descargarAlbaran',
    loginMarcaBlanca: mode.urlApiMarcablanca+'/api/login',
    listarContratos: mode.urlApiMarcablanca+'/api/contrato/listarContratos',
    crearClienteMarcaBlanca: mode.urlApiMarcablanca+'/api/crearCliente',
    crearContratoMarcaBlanca: mode.urlApiMarcablanca+'/api/contrato/distribucion/nuevo',
    obtenerServiciosMarcaBlanca: mode.urlApiMarcablanca+'/api/servicios/getServiciosContratables',
    obtenerPacksMarcaBlanca: mode.urlApiMarcablanca+'/api/servicios/getPacks',
    obtenerServiciosAdicionalesMarcaBlanca: mode.urlApiMarcablanca+'/api/servicios/getServiciosAdicionales',
    setServiciosMarcaBlanca: mode.urlApiMarcablanca+'/api/servicios/setServicios',
    obtenerTecnico: mode.urlApiMarcablanca+"/api/tecnicos/obtenerTecnico",
    obtenerTramos: mode.urlApiMarcablanca+"/api/tecnicos/horasLibres",
    asignarCita: mode.urlApiMarcablanca+"/api/agenda/setCita",
    visualizarIncidenciasMarcaBlanca: mode.urlApiMarcablanca+'/api/incidencias/visualizar/',
    grabarComentarioIncidenciasMarcaBlanca: mode.urlApiMarcablanca+'/api/incidencias/grabarComentario',
    recuperarServiciosActivosMarcaBlanca: mode.urlApiMarcablanca+'/api/contrato/recuperarServiciosActivos',
    bloquearDesbloquearServicioMarcaBlanca: mode.urlApiMarcablanca+'/api/servicios/bloqueoDesbloqueo',
    confirmarContratoMarcaBlanca: mode.urlApiMarcablanca+'/api/contrato/distribucion/confirmar',
    diagnosticoONTMarcaBlanca: mode.urlApiMarcablanca+"/api/ont/diagnostico",
    nivelesOnt: mode.urlApiMarcablanca+"/api/ont/niveles",
    bajaPedido:mode.urlApiMarcablanca+"/api/servicios/bajaPedido",
    rechazaPedido:mode.urlApiMarcablanca+"/api/servicios/rechazaPedido",
    cambioDomicilio:mode.urlApiMarcablanca+"/api/contrato/cambioDomicilio",
    listarDocumentacionContratos: mode.urlApiMarcablanca+"/api/documentacion/listar",
    obtenerDocumentoMarcaBlanca: mode.urlApiMarcablanca+'/api/documentacion/obtener',
    subirDocumentoMarcaBlanca: mode.urlApiMarcablanca+'/api/documentacion/subir',
    logoutMarcaBlanca: mode.urlApiMarcablanca+'/api/logout',
    bajaContrato: mode.urlApiMarcablanca+"/api/contrato/baja",    
};
