<div class="consumo_content">
    <div class="seleccionAccion btnsActions" *ngIf="!data2 && !listaLllamadas">
        <input type="button" value="VER DETALLE" (click)="verListaLlamadas()">

        <input type="button" value="VER DATOS" (click)="verConsumoDatos()">
    </div>


    <div *ngIf="listaLllamadas">
        <!-- <div class="close" (click)="cerrarAccion('llamadas')">X</div> -->

        <div *ngFor="let grupo of groupListLlamadas | keyvalue; index as i">
            <div>
                <div class="titleLLamada">{{grupo.key}}</div>
                <table>
                    <tr class="titleTable">
                        <th>TIPO DE LLAMADA</th>
                        <th>FECHA</th>
                        <th>HORA</th>
                        <th>DESTINO</th>
                        <th>DURACIÓN</th>
                        <th>DURACIÓN ACUMULADO</th>
                        <th>IMPORTE</th>
                        <th>RED</th>
                    </tr>
                    <tr *ngFor="let linea of grupo.value">
                        <td>{{grupo.key}}</td>
                        <td>{{linea.fecha}}</td>
                        <td>{{linea.hora}}</td>
                        <td>{{linea.dest}}</td>
                        <td>{{convertToMB(grupo, linea.dura)}}</td>
                        <td>{{convertToMB(grupo, linea.acum)}}</td>
                        <td>{{linea.imp}}</td>
                        <td>{{linea.cadenap}}</td>
                    </tr>
                </table>
                <div class="infoLLamadas" *ngIf="grupo.key!='MOVIL: Naveg.Nacional'">
                    <div class="totalLlamadas">
                        Llamadas: <span>{{grupo.value.length}}</span>
                    </div>
                    <div class="totalImporte">
                        Total Importe: <span>{{calcularTotalImporte(grupo.value)}}</span>
                    </div>
                    <div class="totalDuraPtv">
                        Total Duración Red PTV: <span>{{calcularDuraccionDeRedPtv(grupo.value)}}
                            segundos</span>
                    </div>
                    <div class="totalDuraExt">
                        Total Duración Red Externa: <span>
                            {{calcularDuraccionDeRedExterna(grupo.value)}} segundos</span>
                    </div>
                </div>
            </div>

            <div style="height: 10em; color: rgba(0, 0, 0, 0); background-color:white;">
                <label>.</label>
            </div>

        </div>
    </div>
    <div *ngIf="data2!=null">
        <div class="cajaDonuts">
            <div class="donutDatos">
                <h3 class="texto1">Consumo de Datos</h3>
                <h4 class="texto2">Datos totales: {{data2.Bono}}</h4>
                <div class="alinear">
                    <div [ngStyle]="{'margin-left': (!mostrarSegundoCanvas ? '50px' : '0'), 'width': '200px','height': '200px'}">
                        <canvas class="donut" id="myDoughnutChart" baseChart></canvas>
                    </div>
                    <div *ngIf="mostrarSegundoCanvas" style="width:200px; height: 200px;">
                        <canvas class="donut" id="myDoughnutChartRoaming" baseChart></canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>