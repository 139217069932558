<div *ngIf="!load">
    <div style="width: 100%;height: 100%;position: fixed;background-color: white;">
        <mat-grid-list cols=3 rowHeight=40em>
            <mat-grid-tile>

            </mat-grid-tile>
            <mat-grid-tile>
                <img src="assets/img/loading.gif">
            </mat-grid-tile>
            <mat-grid-tile>

            </mat-grid-tile>
        </mat-grid-list>

    </div>
</div>

<div class="formbox" style="width: 100%; height: 90%;">
    <div class="containerLogo">
        <img src="assets/img/disenio2024/NUEVO LOGO OPERADORES-05.webp" class="logo">
    </div>
    <div class="containerText">
        <p>Bienvenido a la
            plataforma de contratación de <strong style="font-family: MontserratMedium;">PTV Telecom</strong>, si tiene
            cualquier incidencia no dude en contactar con nuestro <a href="mailto:operadores@ptvtelecom.com"
                class="asistencia">equipo de soporte.</a>
        </p>
       
    </div>
    <div class="titulopromociones">
        <h2>NUEVAS PROMOCIONES:</h2>
    </div>

    <div *ngIf="load">
        <img src="assets/img/disenio2024/FUSIONADORA.webp" (click)="openPdf('FUSIONADORA PDF CARACTERISTICAS.pdf')" class="cajaPubli">
        <img src="assets/img/disenio2024/ROUTER2.webp"  (click)="openPdf('wifi.pdf')" class="cajaPubli">
        <img src="assets/img/disenio2024/CIRCULO_REALME_12_5G.png" (click)="openPdf('REALME_12_5G_FOLLETO.pdf')" class="cajaPubli">
        <img src="assets/img/disenio2024/OFERTA1.webp" class="cajaPubli">
    </div>


    <div class="promocioncondiciones">
        <p>A los precios indicados en esta comunicación les serán de aplicación los impuestos indirectos correspondientes.</p>
    </div>

    <div class="containerRedesSociales">

        <a target="_blank" href="https://twitter.com/PTVtelecom_com"><img src="assets/img/RedesSociales/PTVicon4.svg"
                class="logoRS"></a>

        <a target="_blank" href="https://www.facebook.com/PTVTelecom/"><img src="assets/img/RedesSociales/PTVicon1.svg"
                class="logoRS"></a>

        <a target="_blank" href="https://www.instagram.com/ptvtelecom/?hl=es"><img
                src="assets/img/RedesSociales/PTVicon3.svg" class="logoRS"></a>

        <a target="_blank" href="https://www.youtube.com/channel/UCJyzDx6ExVS3nGPAuL8U5UA"><img
                src="assets/img/RedesSociales/PTVicon2.svg" class="logoRS"></a>

        <mat-grid-tile [colspan]=2 [rowspan]=1 class="alinearTI">
        </mat-grid-tile>
        <mat-grid-tile [colspan]=8 [rowspan]=1 class="alinearTI">
        </mat-grid-tile>
    </div>

</div>


<!-- NO BORRAR -->




<!-- <div class="box">
             <div class="imgBox">
                <img src="assets/img/PTV/PTV2c.svg" style="height: 80%; width: 75%; margin: auto;">
            </div>
            <div class="details">
                <div class="content">
                    <h4 class="TPTV">Pack a Medida</h4>
                    <p class="TePTV">Con PTV Telecom eliges pagar solo por lo que necesitas.</p>
                </div>
            </div>
        </div>

        <div class="box">
            <div class="imgBox">
                <img src="assets/img/PTV/PTV1c.svg" style="height: 100%; width: 75%;">
            </div>
            <div class="details">
                <div class="content">
                    <h4 class="TPTV">Atención Personalizada</h4>
                    <p class="TePTV">Ofrecemos un servicio totalmente orientado al cliente. Personas que atendemos a personas.</p>
                </div>
            </div>
        </div>

        <div class="box">
            <div class="imgBox">
                <img src="assets/img/PTV/PTV3c.svg" style="height: 80%; width: 75%; margin: auto;">
            </div>
            <div class="details">
                <div class="content">
                    <h4 class="TPTV">Mantenimiento Gratuito</h4>
                    <p class="TePTV">Con PTV disfrutas de un magnífico servicio técnico TOTAL y GRATUITO.</p>
                </div>
            </div>
        </div>

        <div class="box">
            <div class="imgBox">
                <img src="assets/img/PTV/PTV4c.svg" style="height: 100%; width: 75%;">
            </div>
            <div class="details">
                <div class="content">
                    <h4 class="TPTV">Sin Permanencia</h4>
                    <p class="TePTV">El compromiso es de PTV hacia sus clientes  y no de los clientes hacia PTV.</p>
                </div>
            </div>
        </div>

        <div class="box">
            <div class="imgBox">
                <img src="assets/img/PTV/PTV5c.svg" style="height: 100%; width: 75%;">
            </div>
            <div class="details">
                <div class="content">
                    <h4 class="TPTV">Red Propia</h4>
                    <p class="TePTV">Disponemos de red propia y sin intermediarios, lo que nos permite ser más rápidos y eficaces.</p>
                </div>
            </div>
        </div>

        <div class="box">
            <div class="imgBox">
                <img src="assets/img/PTV/PTV6c.svg" style="height: 100%; width: 75%;">
            </div>
            <div class="details">
                <div class="content">
                    <h4 class="TPTV">Ayuda Técnica</h4>
                    <p class="TePTV">Nuestros técnicos informáticos te ayudarán gratuitamente a configurar internet en tus dispositivos.</p>
                </div>
            </div>
        </div> -->