import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewChecked, AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { LoadingServiceService } from '../loading-service.service';
import { KeyValue } from '@angular/common'
import { data } from 'jquery';
import Chart from 'chart.js/auto'

@Component({
    selector: 'app-consumo-component',
    templateUrl: './consumo-component.component.html',
    styleUrls: ['./consumo-component.component.css']
})
export class ConsumoComponentComponent implements OnInit, AfterViewChecked {
    private onCompare(_left: KeyValue<any, any>, _right: KeyValue<any, any>): number {
        return -1;
    }
    listaLllamadas
    groupListLlamadas
    data2: any;
    mostrarSegundoCanvas: boolean = true;
    donutRoaming: boolean = false
    consumo = {
        telefono: ''
    }
    @Input() resetFormSubject: Subject<boolean> = new Subject<boolean>();
    protected newTarifaUpdate: string
    public doughnutChartLabels: string[]
    public doughnutChartLabelRoaming: string[]
    public demodoughnutChartDataRoaming: number[]
    public demodoughnutChartData: number[]
    public doughnutChartType: string
    public grafico: Chart<"doughnut", number[], string>;
    public graficoRoaming: Chart<"doughnut", number[], string>;

    constructor(
        private http: HttpClient,
        private cookies: CookieService,
        public loadService: LoadingServiceService

    ) { }
    ngAfterViewChecked(): void {
        this.checkSegundoCanvas();
        if (this.data2 != null) {
            if (this.grafico == null) this.createDoughnutChart();
            if (this.graficoRoaming == null) this.createDoughnutChartRoaming();
        }
    }
    checkSegundoCanvas() {
        this.mostrarSegundoCanvas = this.data2.Consumor != '0.0GB' || this.data2.Bonor != '0.0GB';
    }
    ngOnInit(): void {
        this.listaLllamadas = null;
        this.data2 = null;
        this.grafico = null;
        this.graficoRoaming = null;
        this.resetValues();
        console.log(this.data2);
        Chart.unregister({ id: 'centerText' });
        Chart.register({
            id: 'centerText',
            beforeDraw: (chart: any) => {
                var datosTotales = null;
                switch (chart.canvas.id) {
                    case 'myDoughnutChart':
                        datosTotales = this.data2.Bono;
                        break;
                    case 'myDoughnutChartRoaming':
                        datosTotales = this.data2.Bonor;
                        break;
                }
                console.log(this.data2);
                console.log(datosTotales.toUpperCase());
                
                if (datosTotales.toUpperCase() == "ILIMITADO") {
                    const ctx = chart.ctx;
                    const width = chart.width;
                    const height = chart.height;
                    const fontSize = (height / 50).toFixed(2);

                    ctx.restore();
                    ctx.font = fontSize + 'em sans-serif';
                    ctx.textBaseline = 'middle';

                    ctx.fillStyle = '#97cef4';

                    const text = '∞';
                    const textX = Math.round((width - ctx.measureText(text).width) / 2);
                    const textY = height / 1.5;

                    ctx.fillText(text, textX, textY);
                    ctx.save();
                    
                }
            }
        });
    }
    /*  ngOnchanges(): void {
         console.log("Hola Mundo1");
     } */

    createDoughnutChart() {
        const ctx = document.getElementById('myDoughnutChart') as HTMLCanvasElement;
        this.grafico = new Chart(ctx, {
            type: 'doughnut',
            data: {
                labels: this.doughnutChartLabels,
                datasets: [{
                    /*   label: 'Restante', */
                    data: this.demodoughnutChartData,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    tooltip: {
                        enabled: true,
                        callbacks: {
                            label: (tooltipItem) => {
                                const label = tooltipItem.label.includes('Total GB') ? 'GB Restantes' : 'Consumo';
                                const value = tooltipItem.raw;
                                return `${label}: ${value}`; 
                            }
                        }
                    }
                }
            }
        });
    }

    createDoughnutChartRoaming() {
        const ctx = document.getElementById('myDoughnutChartRoaming') as HTMLCanvasElement;

        this.graficoRoaming = new Chart(ctx, {
            type: 'doughnut',
            data: {
                labels: this.doughnutChartLabelRoaming,
                datasets: [{
                    label: 'Colores',
                    data: this.demodoughnutChartDataRoaming,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    tooltip: {
                        enabled: true,
                        callbacks: {
                            label: (tooltipItem) => {
                                const label = tooltipItem.label.includes('Total GB Roaming') ? 'GB Restantes' : 'Consumo';
                                const value = tooltipItem.raw;
                                return `${label}: ${value}`;
                            }
                        }
                    }
                }
            }
        });
    }

    /**
     * Retorna un listado de llamadas de lalínea seleccionada
     */
    async verListaLlamadas() {
        await this.loadService.showloading();
        this.listaLllamadas = null;
        this.groupListLlamadas = null;
        this.data2 = null;

        const headers = new HttpHeaders({
            'user': this.cookies.get("user"),
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass"),
        });
        const body = {
            "telefono": this.cookies.get("numero")
            // "telefono": "605363965"
        }
        const uri = environment.getCallsForLine;
        this.listaLllamadas = null;
        this.groupListLlamadas = null;
        try {
            this.http.post(uri, body, { headers }).subscribe((res: any) => {
                if (res.Error) {
                    Swal.fire({
                        icon: "warning",
                        text: res.Error.toUpperCase()
                    })
                } else {
                    this.listaLllamadas = []
                    this.groupListLlamadas = []
                    // this.itemsGroupLlamada = [];

                    res.forEach(element => {
                        this.listaLllamadas.push({
                            telf: element.telf,
                            acum: element.acum,
                            bono: element.bono,
                            cadenap: element.cadenap,
                            dest: element.dest,
                            dura: element.dura,
                            fecha: element.fecha,
                            hora: element.hora,
                            imp: element.imp,
                            tipo: element.tipo,
                            tipon: element.tipon
                        }
                        )
                    });
                    this.groupListLlamadas = this.groupBy(this.listaLllamadas, 'tipo');
                    //  this.groupListLlamadas = Object.keys(this.groupListLlamadas).map((key) =>  this.groupListLlamadas[key]);
                    // this.itemsGroupLlamada = this.groupBy(this.groupListLlamadas, '')
                    // const grouped = this.groupBy(this.listaLllamadas, tipo);
                }
                this.loadService.hideloading()
            });
        } catch {
            this.loadService.hideloading()
        }
    }


    /**
     * Encargado de retornar el consumo en datos de una linea móvil
     */
    verConsumoDatos() {
        this.listaLllamadas = null; //reset
        this.loadService.showloading()

        this.data2 = null;
        this.grafico = null;
        this.graficoRoaming = null;
        try {
            const headers = new HttpHeaders({
                'user': this.cookies.get("user"),
                'token': this.cookies.get("token"),
                'pass': this.cookies.get("pass"),
            });
            this.consumo = {
                "telefono": this.cookies.get("numero")
                // "telefono":"635505383"
            }
            const url = environment.getConsumoMovil;
            this.http.post(url, this.consumo, { headers }).subscribe((res: any) => {
                this.data2 = res;
                console.log(this.data2);

                let balance
                let consumo
                if (this.data2.Bono == "Ilimitado") {
                    balance = 1
                    consumo = 0

                } else {
                    balance = this.data2.Balance
                    consumo = this.data2.Consumo
                }

                this.doughnutChartLabels = ["Consumo: " + this.data2.Consumo, "Total GB: " + this.data2.Bono];
                this.demodoughnutChartData = [parseFloat(consumo), parseFloat(balance)];

                this.doughnutChartLabelRoaming = ["Consumo Roaming: " + this.data2.Consumor, "Total GB Roaming: " + this.data2.Bonor];
                this.demodoughnutChartDataRoaming = [parseFloat(this.data2.Consumor), parseFloat(this.data2.Balancer)];

                this.doughnutChartType = 'doughnut';
                this.loadService.hideloading()
            });
        } catch {
            this.loadService.hideloading()
        }

    }

    /**
     * Util para la agrupación de llamadas por tipo
     * @param objectArray Array de llamadas
     * @param property capo por que queremos filtrar
     * @returns
     */

    groupBy(objectArray, property) {
        // this.tiposDeLlamada = [];
        return objectArray.reduce(function (acc, obj) {
            // this.tiposDeLlamada.push(obj[property]);
            var key = obj[property]
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }

    /**
     * Retorna el balance de la línea
     * @returns
     */
    balance() {
        if (this.data2.Bono == "Ilimitado") {
            this.data2.Balance = "0"
        }
        return this.data2.Balance
    }

    /**
     * Método encargado de cerrar el elemento
     * @param lista elemento en formato string que queremos volver null para ocultar
     */
    cerrarAccion(lista: string) {
        switch (lista) {
            case "datos":
                this.data2 = null;
                break;
            case "llamadas":
                this.listaLllamadas = null;
                break;
        }
    }

    /**
     * Calculo de total Importe de llamadas
     * @param $array
     * @returns
     */
    calcularTotalImporte($array) {
        var totalImporte = 0.0;
        $array.forEach(element => {
            totalImporte += parseFloat(element.imp)
        });
        return totalImporte;
    }
    /**
     * Calculo de total segundos consumidos de red ptv
     * @param $array
     * @returns
     */
    calcularDuraccionDeRedPtv($array) {
        var totalDuracionPTV = 0;
        $array.forEach(element => {

            if (element.cadenap == "PTV")
                totalDuracionPTV += parseInt(element.dura)
        });
        return totalDuracionPTV;
    }

    /**
     * Calculo de total segundos consumidos de red externa
     * @param $array
     * @returns
     */
    calcularDuraccionDeRedExterna($array) {
        var totalDuracionExt = 0;
        $array.forEach(element => {
            if (element.cadenap != "PTV")
                totalDuracionExt += parseInt(element.dura)
        });
        return totalDuracionExt;
    }

    /**
     * Reinicializa el componente si el padre lo ordena
     */
    resetValues() {
        this.resetFormSubject.subscribe(response => {
            if (response) {
                this.listaLllamadas = null;
                this.data2 = null;
            }
        })
    }

    /**
     * Convierte los bytes en Megabytes
     */
    convertToMB(grupo, bytes) {
        if (grupo["key"] == "MOVIL: Naveg.Nacional") return (bytes * Math.pow(10, -6)).toFixed(2);
        else return bytes;
    }
}