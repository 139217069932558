import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from 'src/app/services/auth.service';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { RepositoryDocumentationService } from 'src/app/services/repository-Documentation.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { CDRService } from 'src/app/services/cdr.service';
import { OnDestroy } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { PeticionServiceService } from 'src/app/services/peticion-service.service';
import { environment } from 'src/environments/environment';
import { error } from 'console';


@Component({
    selector: 'app-mobile',
    templateUrl: './mobile.component.html',
    styleUrls: ['./mobile.component.css']

})
export class MobileComponent implements OnDestroy, OnInit {
    currentUrl: string;
    userlog = null
    base64LogoOperator: any = null;
    extLogoOperator: string;
    isExpanded = true;
    showSubmenu: boolean = false;
    isShowing = false;
    showSubSubMenu: boolean = false;
    tipoFibraSelecc: number = MobileComponent.INDIRECTA;
    public static INDIRECTA = 1000;
    public static MARCA_BLANCA = 1001;
    muestraFibra: any

    tipoMB: any;
    tipoIN: any;
    usuario: any;
    doc: any;
    soloVM: boolean;

    @ViewChild('sidenav') sidenav: MatSidenav;
    @ViewChild('movil') movil: MatSidenav;
    @ViewChild('fijo') fijo: MatSidenav;
    @ViewChild('fibra') fibra: MatSidenav;
    @ViewChild('menu') settingsMenu: MatSidenav;
    @ViewChild('menuTrigger') matMenu: any;
    @ViewChild('accordion', { static: true }) Accordion: MatAccordion;
    @ViewChild('indirec') indirec: ElementRef;
    @ViewChild('marca_blanca') marcaBlanca: ElementRef;

    constructor(
        public cdrService: CDRService,
        private router: Router,
        private cookie: CookieService,
        public repoService: RepositoryDocumentationService,
        private auth: AuthService,
        private _location: Location,
        private peticionMarcaBlanca: PeticionServiceService,
    ) {
    }

    ngOnInit(): void {
        this.compruebaTipoFibra()
        this.usuario = this.cookie.get("user");
        this.doc = this.cookie.get("doc");
        this.soloVM = this.cookie.get("soloVM") === 'true';

    }

    ngOnDestroy(): void {
        this.ngOnDestroy();
    }

    closeAllPanels() {
        this.Accordion.closeAll();
    }

    isAuth() {
        return this.auth.isloged();
    }

    mouseenter() {
        if (!this.isExpanded) {
            this.isShowing = true;
        }
    }

    mouseleave() {
        if (!this.isExpanded) {
            this.isShowing = false;
        }
    }

    showMessage() {
        // Swal.fire({
        //   icon: 'error',
        //   title: 'Ups, algo ha ocurrido.',
        //   text: 'Lo sentimos, esta opción no está disponible.',
        //   footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
        //   background: '#F5F5F5',
        // })
    }

    settingsConfigOperator() {
        var htmlContent = ``;
        htmlContent += `<div>`;
        htmlContent += `<div>Cambiar logo del operador </div>`;
        htmlContent += `</div>`;
        htmlContent += `<div>Buscar Documentacion</div>`;

        Swal.fire({
            html: htmlContent
        })
    }

    logout() {
        this.logoutMarcaBlanca();
        this.auth.logout();
        this.fibra.close();
        this.movil.close();
        this.fijo.close();
        this.cookie.set("user", "");
        this.cookie.set("doc", "");
    }
    async logoutMarcaBlanca() {
        var token = this.cookie.get("tokenMarcaBlanca");
        var pass = this.cookie.get("pass");
        var tercero = this.cookie.get("tercero");

        if ((token && token != '') && ((pass != '') && (tercero != ''))) {
            await this.peticionMarcaBlanca.peticionMarca(environment.logoutMarcaBlanca, 'get');
        }
    }

    goBack() {
        this.currentUrl = this.router.url;

        //SI ES CUALQUIER OTRA PAGINA DISTINTA DE LA PAGINA HOME NOS VOLVERÁ UN PASO ATRAS
        if (this.currentUrl != "/welcome") {
            this._location.back();
        }
        // if (this.currentUrl != "/lineamovil") {
        // }
        this.fibra.close();
        this.movil.close();
        this.fijo.close();
    }

    /**
     * Método encargado de la modificacion del logo de cada operador
     */
    updateLogotypeOperator() {
        let operator = this.cookie.get("user");
        // this.repoService.downloadLogoOperator("logo_"+operator)
        this.repoService.downloadLogoOperator("logo_" + operator);
        var imgHtml = ``;
        // if (this.repoService.logoOperatorBase64) {
        var imgHtml = `<div >
            <img src="data:image/jpeg;base64,` + this.repoService.logoOperatorBase64 + `" alt="" style="width:200px;">
            </div>`;

        Swal.fire({
            html: imgHtml,
            //  imageUrl:this.repoService.logoOperatorBase64,
            title: "MODIFICACION DE LOGO",
            input: "file",
            inputLabel: "seleccione su nuevo logo si desea modificarlo.. archivos compatibles: png, jpg\nMax: 3Mb"
        }

        ).then(response => {
            if (response.isConfirmed) {
                var file = document.getElementById("swal2-input") as HTMLInputElement;
                var reader = new FileReader();
                reader.readAsDataURL(file.files[0]);
                reader.onload = () => {
                    this.base64LogoOperator = reader.result;
                    let ext = file.files[0].name.split(".").pop();
                    let fileSizeMb = (file.size / 1024) / 1024;
                    if (fileSizeMb > 3) { //SI SE EXCEDE LOS 5MB
                        Swal.fire({
                            title: "ARCHIVO DEMASIADO GRANDE",
                            icon: "warning",
                            text: "El archivo supera el límite de 5Mb."
                        });
                    } else if (ext != "jpg" && ext != "png" && ext != "jpeg") {
                        Swal.fire({
                            title: "ARCHIVO INVÁLIDO",
                            icon: "warning",
                            text: "El tipo de archivo no es compatible."
                        });
                    } else {
                        this.repoService.uploadLogoOperator(this.base64LogoOperator, ext);
                        this.repoService.downloadLogoOperator("logo_" + operator + "." + ext);
                    }
                };
            }
        })
    }

    getNavBar() {
        return this.auth.getViewNavBar();
    }

    /**
     * Método para buscar Por fecha los Documentos y archivos subidos al servidor de un determinado cliente
     */
    //     lookDocumentationForClient() {
    //         this.currentUrl = this.router.url;
    //         var contentHtml = ``
    //             // if(this.currentUrl=="/initmovil"|| this.currentUrl== "/init"){//si la pagina actual es la de nueva solicitud de fijo o movil
    //         contentHtml += `<div >
    //  <label for="fechaConsulta" class="swal2-input-label" >Introduzca la fecha a consultar</label>
    //  <input class="swal2-input" type="month" name="fechaConsulta" id="fechaConsulta">
    //  </div>`

    //         var mesSelectedFile: number
    //         var yearSelectedFile: number
    //         Swal.fire({
    //             title: "Consulta documentos subidos",
    //             html: contentHtml,
    //             showCancelButton: true,
    //             showConfirmButton: true
    //         }).then(res => {
    //             if (res.isConfirmed) {
    //                 //-------------FECHA SELECTED
    //                 let date = document.getElementById('fechaConsulta') as HTMLInputElement
    //                 let selectDate = new Date(date.value);
    //                 // 
    //                 // mesSelectedFile = (selectDate.getMonth() + 1<10)?'0'+(selectDate.getMonth() + 1)+"":(selectDate.getMonth() + 1)+""
    //                 mesSelectedFile = selectDate.getMonth() + 1
    //                 yearSelectedFile = selectDate.getFullYear()
    //                 let terceroOperador = this.cookie.get("tercero");
    //                 let telefono = this.cookie.get("numero");
    //                 this.repoService.getDocs(terceroOperador, telefono)
    //             }
    //         })
    //     }

    /**
     * Este metodo se encarga de cada vez que se haga click en el boton de ajustes nos indicara en que pagina nos encontramos
     */
    ajustesLaunch() {
        this.currentUrl = this.router.url;
    }

    /**
     *
     * Método encargado de lanzar la peticion listCDR
     *  */
    launchCDR() {
        // this.router.navigate(['cdr-page'])
        this.cdrService.getListCdr();
    }

    goToInitmovil() {
        this.movil.close();
        this.closeAllPanels();
        this.redirectTo('/initmovil');
    }

    goToInitfijo() {
        this.fijo.close();
        this.redirectTo('/init');
    }

    redirectTo(uri: string) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate([uri]));
    }

    seleccionarTipo(tipo: number) {
        switch (tipo) {
            case MobileComponent.INDIRECTA:
                this.indirec.nativeElement.style = 'border-bottom: 1px solid white;';
                this.marcaBlanca.nativeElement.style = '';
                this.tipoFibraSelecc = MobileComponent.INDIRECTA;
                break;
            case MobileComponent.MARCA_BLANCA:
                this.marcaBlanca.nativeElement.style = 'border-bottom: 1px solid white;';
                this.indirec.nativeElement.style = '';
                this.tipoFibraSelecc = MobileComponent.MARCA_BLANCA;
                break;
        }
    }

    compruebaTipoFibra() {
        this.muestraFibra = false
        this.tipoIN = false
        this.tipoMB = false
        if (this.cookie.get("in") == "true") {
            this.tipoIN = true
            this.muestraFibra = true;
            if (!this.tipoMB) {
                this.tipoFibraSelecc = 1000
            }
        }

        if (this.cookie.get("mb") == "true") {
            this.tipoMB = true
            this.muestraFibra = true

            if (!this.tipoIN) {
                this.tipoFibraSelecc = 1001
            }
        }
    }
}