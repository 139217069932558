import { Component, Inject } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ContratosService } from 'src/app/services/contratos.service';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-modal-apn',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatInputModule, MatSelectModule, 
    FormsModule, MatButtonModule, MatDividerModule, MatIconModule, MatDialogModule],
  templateUrl: './modal-apn.component.html',
  styleUrl: './modal-apn.component.css'
})
export class ModalApnComponent {
  tercero: any;
  numero: any;
  con_prov: any;
  selected: String;
  estaCargando:boolean=false;

  constructor(
    private contratosService: ContratosService,
    public dialogRef: MatDialogRef<ModalApnComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { tercero: any, numero: any, con_prov: any }
  ) {
    this.tercero = data.tercero;
    this.numero = data.numero;
    this.con_prov = data.con_prov;
  }

  public enviarSMS(): void {
    this.estaCargando=true;
    this.contratosService.envioSMSAPN(this.tercero, this.con_prov, this.numero, this.selected)
      .then((res:boolean)=>{
        this.estaCargando=false;
        if(res)this.dialogRef.close();
      });
  }

}
