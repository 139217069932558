import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import Swal from 'sweetalert2';
import { lastValueFrom, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ContratosService {
  data: any;
  contrato = {
    username: '',
    password: ''
  };
  linea = {
    tercero: '',
    contrato: ''
  };

  esContfratoDistribuidor = false;

  constructor(private http: HttpClient,
    private auth: AuthService,
    private cookies: CookieService) {

  }

  getContratos() {

    const headers = new HttpHeaders({
      'user': '30814831Y',
      'token': 'yaCpEmOpR3P1dGs',
      'pass': 'k1doR3fH'
    });
    this.contrato = {
      "username": '30814831Y',
      "password": 'k1doR3fH'
    }
    const url = environment.getContratos;
    return this.http.post(url, this.contrato, { headers })
      .subscribe((data: any) => {
        // console.log(data);
      });
  }

  getLineas() {

    const headers = new HttpHeaders({
      'user': '30814831Y',
      'token': 'yaCpEmOpR3P1dGs',
      'pass': 'k1doR3fH'
    });
    this.linea = {
      "tercero": '116243',
      "contrato": '285505'
    }
    const url = ''; //environment.getLineas;
    return this.http.post(url, this.linea, { headers })
      .subscribe((res: any) => {
        this.data = res;

      });

  }

  getTarifaOrigen(number: any) {
    let url = environment.getTarifaOrigen;
    return this.http.post(url, number)
  }


  downloadCsvPromoVerano(contrato: any) {
    let url = environment.downloadCsvPromoVerano;
    return this.http.post(url, contrato, { responseType: "blob" })
  }

  bloqueoDesbloqueo(peticion: any) {
    let url = environment.bloqueoDesbloqueo;
    let body = {
      "peticion": peticion
    }
    return this.http.post(url, body)
  }

  /**
   * Realiza el envío de un SMS de configuración para el APN de la línea seleccionada.
   */
  async envioSMSAPN(tercero: any, con_prov: any, num_telef: any, plataforma: any): Promise<boolean> {
    let envioCorrecto=false;
    await lastValueFrom(this.http.post(environment.enviarSMSConfigAPN,
        { "tercero": tercero, "con_prov": con_prov, "num_telef": num_telef, "plataforma": plataforma },
        { headers: { 'user': this.cookies.get("user"), 'token': this.cookies.get("token")}})).then((respuesta:any)=>{
          envioCorrecto = true;
          Swal.fire({
            title: respuesta.mensaje, icon: 'success',
            showCancelButton: false, confirmButtonColor: '#00b1aa', confirmButtonText: 'Confirmar',
          });
        }).catch(error=>{
          envioCorrecto = false;
          let mensajeHTML = null;
          if (error.status == 422) {
            mensajeHTML = '';
            for (const key in error.error.mensajes)
              Array.from(error.error.mensajes[key]).forEach(mensaje => mensajeHTML += `<p>${mensaje}</p>`);
          }
          Swal.fire({
            title: 'Error', icon: 'error', showCancelButton: false,
            confirmButtonColor: '#00b1aa', confirmButtonText: 'Confirmar',
            html: mensajeHTML ?? error.error.mensaje ?? error.error.detalle
          });
        });
    return envioCorrecto;
  }

  getContratosFijo(data: any) {
    let header = new HttpHeaders({
      'user': this.cookies.get("user"),
      'token': this.cookies.get("token"),
      'pass': this.cookies.get("pass"),
    })
    let url = environment.getContratos
    return this.http.post(url, data, { headers: header })

  }
  getDocumentacionMarcaBlanca(data) {
    let url = environment.listarDocumentacionContratos
    return this.http.post(url, data)
  }

  puedeDobleGiGas() {
    const url = environment.puedeDobleGigas;

    const headers = new HttpHeaders({
      "user": this.cookies.get("user"),
      "token": this.cookies.get("token"),
      "pass": this.cookies.get("pass")
    });

    const body = {
      "tarifa": this.cookies.get("tarifa")
    }

    return this.http.post(url, body, { headers })

  }
}



