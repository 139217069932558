<!-- <div class="contentTable">
    <div class="wrappertable">
        <div class="tableScroll">
            <span (click)="buscarLineaService.cerrarModal()" class="cierreModal" title="Cerrar Búsqueda">X</span>
            <div id="datatableLineas" class="datatableLineas" *ngIf="buscarLineaService.listaLineasClienteBuscado">
                <span (click)="buscarLineaService.cerrarModal()" class="cierreModal" title="Cerrar Búsqueda">X</span>
                <table class="table table-striped table-bordered table-sm row-border hover" datatable
                    [dtOptions]="dtOptions" id="dt" *ngIf="buscarLineaService.listaLineasClienteBuscado">
                    <thead class="headerTable" style="font-family: MontserratBold; text-transform: uppercase;">
                        <tr>
                            <th>Propietario </th>
                            <th>Movil</th>
                            <th>Estado</th>
                            <th>Tarifa</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody class="bodyTable">
                        <tr class="rowLinea" *ngFor="let l of buscarLineaService.listaLineasClienteBuscado"
                            title="Ver detalles de la linea">
                            <td>
                                <div class="lineaDes"> {{l.des}} </div>
                            </td>
                            <td class="lineaTelf">
                                <div> {{l.telf}} </div>
                            </td>
                            <td class="lineaEstaLi">
                                <div> {{l.estaLi }} </div>
                            </td>
                            <td class="lineaTarifa">
                                <div> {{l.tarifa}} </div>
                            </td>
                            <td>
                                <button class="verDetallesBtn" (click)="buscarLineaService.verDetallesDeLinea(l)">
                                    VER DETALLES
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style="height: 10em; color: rgba(0, 0, 0, 0); ">
                <label>.</label>
            </div>
        </div>
    </div> -->

    <!------------------------------------------------- TABLA V2 ------------------------------------------------->

    <div class="contentTable">
        <div class="wrappertable">
            <div class="tableScroll">   
                <span  class="cierreModal" title="Cerrar Búsqueda" (click)="buscarLineaService.cerrarModal()">X</span>            
                <div id="datatableLineas" class="datatableLineas" *ngIf="buscarLineaService.listaLineasClienteBuscado">                    
                    <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" id="dt" *ngIf="buscarLineaService.listaLineasClienteBuscado">
                        <thead class="headerTable" style="margin-top: 10px;">
                            <tr>
                                <th>Propietario </th>
                                <th>Movil</th>
                                <th>Estado</th>
                                <th>Tarifa</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody class="bodyTable">
                            <tr class="rowLinea" *ngFor="let l of buscarLineaService.listaLineasClienteBuscado" title="Ver detalles de la línea" (click)="buscarLineaService.verDetallesDeLinea(l)">
                                <td><div class="lineaDes">{{l.des}}</div></td>
                                <td class="lineaTelf"><div>{{l.telf}}</div></td>
                                <td class="lineaEstaLi"><div>{{l.estaLi}}</div></td>
                                <td class="lineaTarifa"><div>{{l.tarifa}}</div></td>
                                <td><button class="verDetallesBtn">VER DETALLES</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="spacer"></div>
            </div>
        </div>
    </div>